<script>

import { popularizeInfo,popularizeOutMoney } from "@/api/my";

export default {
    name:"Kickback",
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
    },
    data() {
        return {
            money:'',
            outMoney:null,
            status:1,

            header: {
                mainTitle: getWord(['sign_up', 'commission']),
            },
        }
    },
    methods: {

        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },

        right_click(){
            this.$router.push({path: "/my/registration_money/list"});
        },

        getMoney(check){
            if (this.money===0) return false;
            if (check===true) {
                if (Number(this.money)<Number(this.outMoney)){
                    return false
                }else {
                    return true
                }
            }

            if(Number(this.money)<Number(this.outMoney)){
                return;
            }else{
                popularizeOutMoney().then(result=>{
                    if(result.data.code === 'SUCCESS'){
                        this.$router.push({path: "/my/registration_money/yes"});
                    }else{
                        app.Mint.Toast(result.data.msg);
                    }
                })
            }            
        }
    },
    mounted() {
        popularizeInfo().then(result=>{
            if(result.data.code=='SUCCESS'){
                this.money = result.data.result.money;
                this.outMoney = result.data.result.out_money;
                this.status = result.data.result.status;
            }else{
                app.Mint.Toast(result.data.msg);
            }
        })
    },
    computed: {
        language(){
            return window.language
        }
    },
}
</script>
<template>
    <section id="kickback" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <img class="backImg" src="@@/assets/images2/user/zucemoney_back.png" />
            <p class="money">
                {{ currencySymbol() }}
                {{money}}
                <!-- <span>{{ currencySymbol() }}</span> -->
            </p>
            <p v-if="status==1" class="button" :class="{'no':Number(money)<=Number(outMoney),'yes':Number(money)>=Number(outMoney)}" @click="getMoney">{{ getWord('transfer_in4') }}</p>
            <p v-else class="button no">{{ getWord('transfer_in4') }}</p>
            <p class="text" v-if="outMoney">
                {{ 
                    getWord('kickback_withdraw_conditions',{
                        $1:outMoney,
                        $2:currencySymbol()
                    }) 
                }}
            </p>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            >
                <button class="right-menu" slot="right-menu" @click='right_click'>{{ getWord(['commission', 'detail']) }}</button>
            </Header>
            <img class="backImg" :src="require('@@/assets/images/personal/bg/kickback.png')" />
            <h4>{{ getWord(['personal_centre2','commission']) }}</h4>
            <h5 class="money">
                {{ currencySymbol() }}{{ money }}
                <!-- <span>{{ currencySymbol() }}</span> -->
            </h5>
            <a class="button" :class="{available:getMoney(true)}" @click="getMoney">{{ getWord('transfer_in4') }}</a>
            <p class="text">
                {{ getWord('promo_rule') }}
                <span>{{outMoney}}</span>
                {{ getWord('promo_rule2') }}
            </p>
        </template>
        
        
    </section>
</template>
<style lang="scss" scoped type="text/css">
#kickback {
    overflow: auto;

    &.template-2 {

    }

    &.template-3 {

        .right-menu {
            position: absolute;
            right: .15rem;
            top: 0;
            height: 100%;
            background: 0;
            border: 0;
            color: #ffffff;
            font-size: .32rem;
            font-weight: 500;
        }

        h4 {
            font-size: 0.28rem;
            text-align: center;
            margin-top: -.25rem;
            font-weight: normal;
            margin-bottom: .25rem;
        }

        .money {
            color: #000000;
            font-size: .7rem;

            span {
                font-size: .3rem;
                font-weight: normal;
            }
        }

        .button {
            display: block;
            background: #B0B0B0;
            color: #FFFFFF;
            font-size: 0.34rem;
            margin: 0 auto;
            width: 84%;
            border-radius: .14rem;
            padding: .275rem 0;
            line-height: 1;
            height: auto;
            margin-top: .5rem;

            &.available {
                background:#EC2829;
            }
        }
    }
}
.backImg{
    width:40%;
    margin:20% 30% 5% 30%;
}
.money{
    width:100%;
    text-align: center;
    font-size:1rem;
    color:#FC7320;
    span{
        font-size:0.5rem;
        margin-left:0.15rem;
    }
}
.button{
    width:80%;
    height:1rem;
    margin:5% 10%;
    font-size:0.4rem;
    text-align: center;
    line-height:1rem;
    border-radius:0.2rem;
}
.text{
    width:60%;
    margin:5% 20%;
    text-align: center;
    font-size:0.3rem;
    color:#999;
    span{
        color:red;
    }
}
.no{
    background:#D3D3D3;
    color:#999;
}
.yes{
    background:#3985E1;
    color:#FFF;
}
</style>
